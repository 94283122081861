const AutoCallbacks = {
  // There currently are none
};

function pd_debug(arg) {
  try {
    if (!arg) {
      return document.cookie.split(';').filter(item => item.trim().startsWith('_pd_debug=')).length;
    }
    return document.cookie.split(';').some(item => item.includes('_pd_debug=' + arg));
  } catch (e) {
    return false;
  }
}

function is_production() {
  return !window.location.origin.match(/(localhost|maude|10.0.1|platemark\.lo)/);
}

function pd_error(msg, ...args) {
  pd_console(msg, args);
  if (msg instanceof Error) {
    console.log(args);
    console.trace();
    throw new Error(msg);
  }
}

function pd_console() {
  var MyDate = new Date();
  var MyDateString;
  MyDateString =
    '[' +
    ('0' + MyDate.getDate()).slice(-2) +
    '-' +
    (MyDate.toLocaleString('default', { month: 'short' }).toUpperCase() +
      '-' +
      MyDate.getFullYear() +
      ' ' +
      ('0' + MyDate.getHours()).slice(-2) +
      ':' +
      ('0' + MyDate.getMinutes()).slice(-2) +
      ':' +
      ('0' + MyDate.getSeconds()).slice(-2)) +
    ']';

  var log = console.log;

  // Only log if cookie is present or not production
  if (!pd_debug() && is_production()) {
    return;
  }

  // 1. Convert args to a normal array
  var args = Array.from(arguments);
  // OR you can use: Array.prototype.slice.call( arguments );

  // 2. Prepend log prefix log string
  args.unshift(MyDateString + ':');

  // 3. Pass along arguments to console.log
  log.apply(
    console,
    args.map(arg => {
      if (is_object(arg)) {
        try {
          return JSON.stringify(arg, null, 2);
        } catch (e) {
          return arg;
        }
      }
      return arg;
    })
  );
}

function is_object(obj) {
  return typeof obj === 'function' || typeof obj === 'object';
}

function h_is_mobile() {
  var check = false;
  (function(a, b) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

/** Server and Component Communications * */

/**
 * @param request_data
 *            is the object {} passed to admin-ajax
 * @param beforeSend
 *            callback called just after this beforeSend
 * @param done
 *            callback called just after this done
 * @param always
 *            callback called just after this always
 * @param fail
 *            callback called just after this always
 * @param request_method
 *            GET or POST
 * @returns
 *
 * <pre>
 * TYPICAL PROTOTYPE
 * ajax_entry_point({
 *     ajax_obj.valid_args.ACTION: ''
 * }, function (xhr) { // beforeSend
 * }, function (response, status ) { // done
 *     if (ajax_obj.valid_responses.SUCCESS == status) {
 *     } else {
 *     }
 * }, function (response, textstatus, xhr) { // always
 * }, function (jqXHR, textStatus) { // fail
 * });
 * </pre>
 */
function ajax_entry_point(request_data, beforeSend, done, always, fail, request_method) {
  request_method = request_method || 'GET';
  beforeSend =
    beforeSend ||
    function() {
      return true;
    };
  always =
    always ||
    function() {
      return true;
    };
  fail =
    fail ||
    function(jqXHR, textStatus, errorThrown) {
      pd_console(request_data);
      pd_console(errorThrown);
      return false;
    };

  var reload_triggered = false,
    logged_in = $('body').hasClass('logged-in');

  if (!request_data) return pd_console('Missing request data');

  // Always this action
  request_data[ajax_obj.valid_args.INPUT_ACTION] = ajax_obj.ajaxaction;

  // Add the nonce info to the request
  var nonce = logged_in ? ajax_obj.nonce_admin : ajax_obj.nonce_user;
  request_data[ajax_obj.valid_args.INPUT_LOGGED_IN] = logged_in;
  request_data[ajax_obj.valid_args.INPUT_WP_NONCE] = nonce;

  try {
    h_verify_input_args(request_data);
  } catch (err) {
    pd_console(err);
    throw err;
  }
  if ($('#pd-delegate').hasClass('ajax-' + request_data.action)) {
    return false;
  }
  if (!done) return pd_console('Missing done implementation');

  (function runAjax(retries, delay) {
    delay = delay || 1000;

    const submit_ajax = function(token) {
      request_data[ajax_obj.valid_args.INPUT_GRECAPTCHA] = token || null;

      $.ajax({
        url: ajax_obj.ajaxurl,
        method: request_method,
        data: request_data,
        dataType: 'json',
        beforeSend: function(xhr) {
          $('#pd-delegate').addClass('ajax-' + request_data.action + ' cursor-progress');

          beforeSend(xhr);
          try {
            xhr.setRequestHeader('X-WP-Nonce', ajax_obj.nonce_user);
          } catch (err) {}
        }
      })
        .done(function(response, textStatus, jqXHR) {
          $('#pd-delegate').removeClass('ajax-' + request_data.action + ' cursor-progress');
          if ('undefined' === typeof response || !response) {
            pd_console(response);
            pd_console(textStatus);
            pd_console(jqXHR);
            return pd_console('Fatal error');
          }
          if (ajax_obj.valid_responses.FAILURE == response.status) {
            pd_console(response);
            if (ajax_obj.valid_responses.KILL_SESSION == response[ajax_obj.valid_responses.STATUS]) {
              return window.location.replace(response[ajax_obj.valid_responses.PUSH_STATE_URL] || '/');
            }
            if (response[ajax_obj.valid_responses.RELOAD_SESSION] && response[ajax_obj.valid_responses.VERSION]) {
              // Cookie indicates session refreshed to avoid infinite
              // loops
              if (
                document.cookie.replace(/(?:(?:^|.*;\s*)_h_version\s*\=\s*([^;]*).*$)|^.*$/, '$1') !=
                response[ajax_obj.valid_responses.VERSION]
              ) {
                document.cookie =
                  '_h_version=' +
                  response[ajax_obj.valid_responses.VERSION] +
                  '; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT';
                reload_triggered = true;
                // return pd_alert(response[ajax_obj.valid_responses.MSG], function(modal) {
                //   return window.location.reload(true);
                // });
              }
            }
          }
          done(response, response[ajax_obj.valid_responses.STATUS], textStatus, jqXHR);
        })
        .fail(function(jqXHR, textStatus, errorThrown) {
          console.log(retries); // print retry count
          0 < retries &&
            setTimeout(function() {
              try {
                grecaptcha.reset();
              } catch (err) {
                // Nothing
              }
              runAjax(--retries);
            }, delay);
          if (0 < retries) {
            return;
          }
          $('#pd-delegate').removeClass('ajax-' + request_data.action);
          pd_console(jqXHR);
          pd_console(textStatus);
          pd_console(errorThrown);
          fail(jqXHR, textStatus, errorThrown);
          console.trace();
        })
        .always(function(response, textStatus, jqXHR) {
          if (response[ajax_obj.valid_responses.RELOAD_SESSION] && !reload_triggered) {
            return window.location.reload(true);
          }
          // if ( $('.h-not-production') ) {
          //   a11yChecker();
          // }
          always(response, textStatus, jqXHR);
          pd_console(response);
          pd_console(textStatus);
          pd_console(jqXHR);
        });
    };

    if (typeof grecaptcha !== 'undefined') {
      grecaptcha.ready(function() {
        // do request for recaptcha token
        // response is promise with passed token
        grecaptcha
          .execute(ajax_obj.grecaptcha_token, {
            action: 'new_inquiry'
          })
          .then(function(token) {
            submit_ajax(token);
          })
          .catch(error => {
            submit_ajax(); // null disables grecaptcha and lets it go through
            if (error) {
              console.log('Error during grecaptcha', error);
            }
          });
      });
    } else {
      submit_ajax();
    }
  })(5, 1000);
}

function validate_input_fields(form, btn) {
  // Set up form validation.. see https://getbootstrap.com/docs/4.0/components/forms/?#validation

  if (!$(form).hasClass('needs-validation')) {
    return true;
  }

  var validateFields = function(input) {
    return $(input)[0].checkValidity();
  };

  $(form)
    .find('.is-invalid')
    .removeClass('is-invalid');

  var required_fields = $(form).find('input[required="required"]'),
    needs_validation = $(form).find('.needs-validation'),
    passed_validation = true;

  required_fields.add(needs_validation).each(function(idx, input) {
    if (validateFields(input) === false) {
      passed_validation = false;
    }
  });

  $(form)
    .find(':invalid')
    .add($(btn))
    .toggleClass('is-invalid', !passed_validation);

  return passed_validation;
}

function font_spy() {
  require('jquery-fontspy/jQuery-FontSpy.js');
  fontSpy('frutiger45_light,italiandidotnormal', {
    success: function() {
      return Promise.resolve('font_spy');
    },
    failure: function() {
      return Promise.reject('font_spy did not load');
    }
  });
}

/**
 * @param element
 *            In case you want to use it in callback
 * @param f_event
 *            return the requisite analytics event object
 * @returns void
 *
 * <pre>
 * log_google_analytics($(btn), function(element) {
 *     return {
 *         page : '/party-orig/',
 *         eventCategory : &quot;filter-position&quot;,
 *         eventAction : &quot;click&quot;,
 *         eventLabel : filterby ? filterby : &quot;reset&quot;
 *     };
 * });
 * </pre>
 */
function log_google_analytics(element, f_event) {
  var event_obj = [];
  if (f_event && f_event instanceof Function) {
    event_obj = f_event(element);
  }
  if (event_obj) {
    try {
      var gtag_data_base = {
          page_path: location.pathname,
          page_title: document.title,
          page_location: location.href
        },
        gtag_obj = { ...gtag_data_base, ...event_obj };

      // sanitize all properties to remove unwanted chars
      var sanitized_gtag = {};
      $.each(gtag_obj, (idx, item) => {
        idx = idx.replace(/[^\w]/g, '_').toLowerCase();
        sanitized_gtag[idx] = item;
      });

      if (pd_debug('incognito')) {
        throw 'Incognito mode on, skipping all analytics';
      }
      if (!is_production() || typeof gtag !== 'function') {
        throw 'gtag disabled, skipping all analytics';
      }

      gtag('event', event_obj.event_name, sanitized_gtag);

      pd_console('GA Analytics Object', sanitized_gtag);

      // gtag has debugging capabilities, ga() does not
      if (!is_production() || pd_debug()) {
        throw 'Debug Mode On, skip UA analytic';
      }
      ga('set', 'page', location.pathname);
      ga('send', 'pageview');
      ga('send', {
        hitType: 'event',
        eventCategory: sanitized_gtag.event_category,
        eventAction: sanitized_gtag.event_action,
        eventLabel: sanitized_gtag.event_label
      });
    } catch (err) {
      pd_console(err);
      pd_console(sanitized_gtag);
    }
  }
}

function h_push_state(url, state_data, callback) {
  if (!url) return;

  // If changing pages, don't preserve slide ? Optional

  // Don't obliterate any existing query args
  var saved_kvp = document.location.search.substr(1) ? document.location.search.substr(1).split('&') : [], // [ 'slide=1234', 'paged=2' ]
    saved_hash = document.location.hash, // #grid-scroll
    new_url = url
      .replace(/^[^?]+/, '')
      .substr(1)
      .split('#'), // [ 'paged=3&something=4', 'somehash' ]
    new_hash = new_url.length > 1 ? '#' + new_url[1] : '',
    new_kvp = new_url[0].split('&'), // [ 'paged=3','something=4' ]
    save_href = url.match(/^[^?]+/), // URL might be full-on link, should strip it
    is_advanced_search = url.match(/\/works\//),
    is_paged = url.match(/paged/),
    hash = is_advanced_search || is_paged ? '' : new_hash || saved_hash;

  // Remove existing kvps whose new kvp would cause a duplicate
  var saved_kvp_adjusted = [];
  $.each(saved_kvp, function(idx, value) {
    var existing_key = value.split('=')[0],
      existing_value = value.split('=')[1],
      is_dupe = false;
    $.each(new_kvp, function(idx, value) {
      var new_key = value.split('=')[0];
      if (existing_key == new_key) {
        is_dupe = true;
        return false;
      }
    });

    // If searching, don't preserve previous search ?
    if (!is_dupe && !is_advanced_search && !is_paged) {
      saved_kvp_adjusted.push(existing_key + '=' + existing_value);
    }
  });

  var final_url_str = ((save_href && save_href[0]) || '') + '?' + saved_kvp_adjusted.concat(new_kvp).join('&') + hash;

  history.replaceState(
    state_data || {
      stuff: 0,
      you_need: 'will be returned below in event.state to trigger any components to refresh'
    },
    '',
    final_url_str
  );
  // TODO, if necessary push state
  window.onpopstate = function(event) {
    console.log('location: ' + document.location + ', state: ' + JSON.stringify(event.state));
  };
}

/**
 * Use this to help determine which images are being used
 * at which breakpoint. Will print lots of useful information
 * on shown resolution, actual resolution, DPR and more
 * Usage:
 *      var elems = document.querySelectorAll('.c-hero-banner__background img')
 *      responsiveImageDebugOutput(elems);
 *
 * Author: Carl-Erik Kopseng
 * Source: https://gist.github.com/fatso83/55fc446df3f3965ecd66e8307a5dc0e6
 */

function responsiveImageDebugOutput(img) {
  if (is_production() || !pd_debug()) {
    return;
  }

  if (!img) {
    throw new TypeError('Expected an image node. Got none.');
  }

  if (Array.isArray(img)) {
    addToAllElements(img);
    return;
  }

  if (arguments.length > 1) {
    addToAllElements([].slice.apply(arguments));
    return;
  }

  function addToAllElements(elems) {
    elems.forEach(function(el) {
      responsiveImageDebugOutput(el);
    });
  }

  var evalPrint = function(arg1, arg2, argN) {
    var len = arguments.length;
    var s = '';
    while (len--) {
      var arg = arguments[len];
      var val = eval(arg);

      if (val) {
        s += '[' + arg + '=' + val + ']  ';
      }
    }
    console.log(s);
  };

  var listener = function() {
    //Old browser
    if (typeof img.currentSrc === 'undefined') {
      evalPrint('img.src');
    }

    //Modern browser
    else {
      evalPrint('img.currentSrc');
      evalPrint('img.id');
      evalPrint('img.width', 'img.height');
      evalPrint('img.naturalWidth', 'img.naturalHeight');
      evalPrint('window.devicePixelRatio');
      var xDim = img.naturalWidth * window.devicePixelRatio;
      var yDim = img.naturalHeight * window.devicePixelRatio;
      console.log('Resolution: ' + xDim + 'x' + yDim);
    }
  };

  // run the listener function if the image had already loaded
  // before the listener had been set up
  if (img.complete) listener();

  img.addEventListener('load', listener);
  // var elem = document.querySelector('.c-hero-banner__background img')
  // responsiveImageDebugOutput(elem);
  // var elems = document.querySelectorAll('.c-hero-banner__background img')
  // responsiveImageDebugOutput(elems);
}

function control_scrolling() {
  $('a.pd-scroll').click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate(
          {
            scrollTop: target.offset().top
          },
          700,
          'easeInOutCirc',
          function() {
            // Callback after animation
            // Must change focus!
            var $target = $(target);
            $target.focus();
            if ($target.is(':focus')) {
              // Checking if the target was focused
              return false;
            } else {
              $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
              $target.focus(); // Set focus again
            }
          }
        );
      }
    }
  });
}

/**
 * @param response
 *            Object returned from server
 * @param enclosing_element
 *            on which we should attach this alert to
 * @param position
 *            append, html, before, after
 * @param status_class
 *            bootstrap color indicator
 * @param animation
 *            animate class
 * @returns
 */
function h_show_inline_alert(response, enclosing_element, position, status_class, animation) {
  var position = position || 'html',
    re = /animate__/gi,
    animation = animation || 'shakeX',
    animation = animation.replace(re, ''),
    animation = 'animated ' + animation,
    decodeHtml = function(html) {
      var txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    },
    status = response[ajax_obj.valid_responses.STATUS],
    status_class = 'alert ' + (status_class ? status_class : status ? 'alert-success' : 'alert-danger'),
    msg = decodeHtml(response[ajax_obj.valid_responses.MSG]),
    markup = '<div class="h-inline-alert ' + status_class + ' ' + animation + '">' + msg + '</div>';
  switch (position) {
    case 'html':
      enclosing_element.html(markup);
      break;
    case 'before':
      enclosing_element.before(markup);
      break;
    case 'after':
      enclosing_element.after(markup);
      break;
    case 'append':
      enclosing_element.append(markup);
      break;
    default:
      break;
  }
}

function h_render_component(selector_id, render) {
  $(selector_id).html(render);
  convertToFraction();
}

/**
 * Recursively scrubs all null object values = useful for sending to analytics
 * @param  {[type]} obj [description]
 * @return {[type]}     [description]
 */
function scrubObject(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
    if (typeof obj[propName] === 'object' && !Array.isArray(obj[propName])) {
      let deep_obj = scrubObject(obj[propName]);
      delete obj[propName];
      obj = { ...obj, ...deep_obj };
    }
  }
  return obj;
}

function h_verify_input_args(submitted_data) {
  var invalid_args = [];
  $.each(submitted_data, function(attempted_arg) {
    var found = false;
    $.each(ajax_obj.valid_args, function(key, value) {
      if (value == attempted_arg) {
        found = true;
        return true;
      }
    });
    if (!found) {
      invalid_args.push(attempted_arg);
    }
  });
  if (0 < invalid_args.length) {
    throw 'Invalid query arg(s): ' + invalid_args.join(', ');
  }
}

function h_auto_callback(callback) {
  if (!callback) return;
  var fn = AutoCallbacks[callback];
  if ('function' === typeof fn) {
    fn();
  }
}

function observeRendering(wrapper, callback) {
  // Select the node that will be observed for mutations
  const targetNode = $(wrapper).get(0);

  // Options for the observer (which mutations to observe)
  const config = { attributes: true, childList: true, subtree: true };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);

  // Start observing the target node for configured mutations
  observer.observe(targetNode, config);
}

export {
  h_is_mobile,
  h_render_component,
  h_show_inline_alert,
  pd_console,
  pd_error,
  h_auto_callback,
  font_spy,
  h_push_state,
  scrubObject,
  log_google_analytics,
  control_scrolling,
  responsiveImageDebugOutput,
  validate_input_fields,
  ajax_entry_point,
  observeRendering
};
