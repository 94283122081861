import '@scss/fonts/font-styles.scss';
import '@src/index.scss';
import { listeners } from './routes/lib/handlers/listeners.js';
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;
$.noConflict( true ); // https://wordpress.org/plugins/booking/

const routes = [
  'blog',
  'press',
  'projects',
  'consultation',
  'schedule',
  'about-platemark',
  'testimonial',
  'home',
  'contact',
  'services',
  'default',
  'faq'
];

function getModuleName() {
    const bodyClasses = $( 'body' )
            .attr( 'class' )
            .split( ' ' )
            .map( theClass => theClass.replace( '-js', '' ) ),
        route = bodyClasses.filter( value => routes.includes( value ) );
    if ( Array.from( new Set( route ) ).length > 1 ) {
        throw 'Invalid route config';
    }
    return route[ 0 ] || 'default';
}

jQuery( document ).ready( () => ( function init() {
    import (
        /* webpackChunkName: "bootstrap-collapse" */
        /* webpackPrefetch: true */
        '../node_modules/bootstrap/js/dist/collapse.js'
    ).then( module => {
        $( '.collapse' ).collapse( { toggle: false } );
    } );

    import (
        /* webpackChunkName: "bootstrap-dropdown" */
        /* webpackPrefetch: true */
        '../node_modules/bootstrap/js/dist/dropdown.js'
    );

    import (
        /* webpackChunkName: "[request]" */
        /* webpackPreload: true */
        './routes/common.js'
    ).then( module => {
        module.h_init();
    } );

    const module_name = getModuleName() + '.js';
    import (
        /* webpackChunkName: "[request]" */
        /* webpackPreload: true */
        `./routes/${ module_name }`
    )
        .then( module => {
            const init = module.default;
            console.log( 'loaded ' + module_name );
            init();
            listeners();
        } )
        .catch( error => 'An error occurred while loading the component' );
} )() );
